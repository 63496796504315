
export default {
  name: 'FooterBottom',
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ '../../../components/ui/Logo'),
    LanguageSelector: () => import(/* webpackChunkName: "LanguageSelector" */ '../../../components/ui/LanguageSelector')
  },
  computed: {
    appVersion () {
      return process.env.UI_VERSION || '1.0'
    },
    footerCopyrightText () { 
      return '© ' + this.$t('copyright_text').replace('VERSION', ` <a href="${this.localePath('changelog')}" class="app-version" style="color: #89919D;text-decoration: underline;" target="_blank">v${this.appVersion}</a>`) + ' ('+(new Date()).getFullYear()+')'
    }
  }
}
